<template>
  <div class="container">
    <div class="content">
      <p class="label">当前使用模板：</p>
      <div class="item">
        <img src="https://img0.qll-times.com/cdn/zgimg/shop-1.png">
        <el-radio class="radio" v-model="configs.templateType" label="1">简洁版</el-radio>
        <p class="tips">适用于简单陈列多个商品，并进行线上销售的企业</p>
      </div>
      <div class="item">
        <img src="https://img0.qll-times.com/cdn/zgimg/shop-2.png">
        <el-radio class="radio" v-model="configs.templateType" label="2">精装版</el-radio>
        <p class="tips">适用于多活动、多促销手段进行商品销售的企业</p>
      </div>
      <div class="item">
        <img src="https://img0.qll-times.com/cdn/zgimg/shop-3.png">
        <el-radio class="radio" v-model="configs.templateType" label="3">企业宣传册</el-radio>
        <p class="tips">适用于以手册形式进行产品宣传的企业，支持客户线上报名活动及购买商品</p>
      </div>
    </div>
    <div class="button">
      <el-button @click="updatedShop()" type="primary">保 存</el-button>
    </div>
  </div>
</template>

<script>
export default {
  data(){
    return {
      templateId: '',
      templateName: '',
      configs: {
        templateType: "1"
      }
    }
  },
  mounted(){
    this.getShopConfigs();
  },
  methods: {
    // 查询商城模板信息
    async getShopConfigs(){
      let res = await this.$ZGManager.getcmstmzConfigs(2);
      if(res.status == 200 && res.data){
        this.templateId = res.data.id;
        this.templateName = res.data.name;
        this.configs.templateType = JSON.parse(res.data.configs).templateType;
      }
    },
    
    // 保存商城模板
    async updatedShop(){
      let dialog = await this.$confirm('此操作将立即生效, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      });
      if(dialog != 'confirm') return;
      let res;
      if(this.templateId > 0){
        let params = {
          name: this.templateName,
          configs: JSON.stringify(this.configs)
        }
        res = await this.$ZGManager.updateShopConfigs(this.templateId, params);
        if(res.status == 200 && res.data == "成功"){
          this.$message.success("保存成功");
        }
      }else{
        res = await this.$ZGManager.createShopConfigs("系统默认商城配置", "系统默认商城配置描述", JSON.stringify(this.configs));
        if(res.status == 200 && res.data == "成功"){
          this.$message.success("保存成功");
        }
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.content{
  background-color: #fff;
  padding: 30px;
  display: flex;
}
.label{
  display: inline-block;
  font-size: 14px;
  font-weight: bold;
  color: rgba(0, 0, 0, .65);
  margin-right: 30px;
}
.item{
  display: inline-block;
  width: 250px;
  margin-right: 20px;
  text-align: center;
  img{
    width: 100%;
    box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
  }
  .radio{
    margin-top: 20px;
  }
  .tips{
    color: #777;
    font-size: 12px;
    margin-top: 10px;
  }
}
.button{
  width: 100%;
  margin-top: 15px;
  margin-left: 160px;
}
</style>